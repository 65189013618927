:root {
   --berntsen-dark-blue: #236192;
   --berntsen-dark-blue-50: #23619250;
   --berntsen-blue: #62B5E5;
   --berntsen-light-blue: #62B5E550;
   --berntsen-green: #78BE20;
   --berntsen-green-85: rgba(120, 190, 32, .85);
   --berntsen-green-35: rgba(120, 190, 32, .35);
   --berntsen-gray: rgba(0, 0, 0, .80);
   --berntsen-gray-15: rgba(0, 0, 0, .15);
   --berntsen-gray-30: rgba(0, 0, 0, .30);
   --berntsen-accent-blue: rgba(0, 169, 224);
   --dark-accent: rgb(68, 70, 67);
   --light-accent: rgb(214, 206, 206);
   --berntsen-red: #E03C31;
   --berntsen-warning: #F68D2E;
}

#app {
   min-height: 100vh;
}

body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   background-color: var(--berntsen-gray-30);
   min-height: 100vh;
}

button.btn-primary {
   background-color: var(--berntsen-dark-blue);
}

button.btn-primary i {
   margin-right: 5px;
}

button.btn-primary:hover {
   background-color: var(--berntsen-accent-blue);
}

button.btn-primary:disabled {
   background-color: var(--berntsen-dark-blue-50);
}

a.link {
   color: var(--berntsen-dark-blue);
   text-decoration: underline;
}

.container {
   margin-left: 5%;
   margin-right: 5%;
   min-width: 90%;
}

.navbar {
   background-color: var(--berntsen-gray);
   color: white;
}

.navbar-text {
   font-weight: 500;
}

.nav-item {
   cursor: pointer;
   background-color: var(--berntsen-dark-blue);
   padding: 5px;
}

.dropdown-item:hover {
   font-weight: 500;
}

.dropdown-item:hover {
   font-weight: 500;
}

.dropdown-active {
   background-color: var(--berntsen-dark-blue);
   color: white;
}

.site-heading {
   font-weight: 500;
   font-size: xx-large;
   width:50%;
   text-align: center;
}

.login {
   margin-bottom: 40px;
   div {
      max-width: 500px;
   }
}

.logout:hover,
button:hover {
   background-color: var(--berntsen-blue);
   font-weight: 600;
}

.logo {
   width: 15%;
   margin-right: 10%;
}

.page-title {
   background-color: white;
   padding: 10px;
   font-weight: bold;
}

.wrapper {
   min-height: 100vh;
   display: grid;
   grid-template-rows: auto 1fr auto;
 }

.footer {
   width: 100%;
   margin-left: 0px;
   margin-right: 0px;
   margin-top: 15px;
   padding-top: 15px;
   padding-bottom: 15px;
   text-align: center;
   background-color: var(--dark-accent);
   color: var(--light-accent);
   a {
      color: var(--berntsen-green);
      text-decoration: underline;
   }
}

.org-section-header {
   background-color: var(--berntsen-green-85);
   color: white;
   padding: 10px;
   font-weight: 400;
}

.org-section-header i {
   font-size: small;
   vertical-align:super;
   cursor: help;
}

.main-content {
   border-left: 2px double var(--berntsen-green-35);
   border-right: 2px double var(--berntsen-green-35);
   background-color: white;
   padding-left: 10px;
   padding-right: 10px;
   padding-bottom: 10px;
}

.column {
   margin-left: 20px;
   margin-right: 20px;
}

select,
input {
   margin: 4px;
}

th span {
   color: var(--berntsen-accent-blue);
}

th i {
   margin-left: 5px;
   color: var(--berntsen-accent-blue);
}

.data-parameters {
   font-weight: bold;
}

.data-parameters .tt {
   margin-left: 5px;
   color: var(--berntsen-accent-blue);
}

.center {
   margin: auto;
   width: 50%;
   max-width: 400px;
   min-width: 150px;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   font-size: 20px;
   text-align: center;
}

.text-center {
   text-align: center;
}

.text-right {
   text-align: right;
}

.horizontal-center {
   width: 300px;
   margin: auto;
   text-align: center;
}

div.loader {
   font-size: 100px;
}

.pagination {
   min-width: 450px;
   justify-content: center;
   align-items: center;
}

.pagination button {
   margin-left: 5px;
   margin-right: 5px;
}

.pagination input {
   width: 40px;
   text-align: center;
}

.pagination select {
   margin-right: 30px;
}

input[type='radio'] {
   accent-color: var(--berntsen-accent-blue);
}

/* Hide input spinners in Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

/* Hide input spinners in Firefox */
input[type=number] {
   -moz-appearance: textfield;
}

.tab-header {
   width: 25%;
   text-align: center;
   font-size: 20px;
   padding: 10px;
   cursor: pointer;
   opacity: 0.6;
   border: 0;
   outline: 0;
   border: 2px solid var(--berntsen-green);
   border-top-right-radius: 10px;
   border-top-left-radius: 10px;
}

.tab-header-active {
   width: 25%;
   text-align: center;
   font-size: 20px;
   padding: 10px;
   cursor: pointer;
   opacity: 0.6;
   background: var(--berntsen-green-35);
   border: 0;
   outline: 0;
   border-top: 2px solid var(--berntsen-green);
   border-right: 2px solid var(--berntsen-green);
   border-left: 2px solid var(--berntsen-green);
   border-top-right-radius: 10px;
   border-top-left-radius: 10px;
   opacity: 1;
}

.tab-header-filler {
   width: 50%;
   border-bottom: 2px solid var(--berntsen-green);
}

[aria-disabled="true"] {
   opacity: 0.5;
 }

div.rmsc {
   padding: 0px;
   border: none;
   margin-bottom: 5px;
   margin-left: 4px;

   .dropdown-container {
      border-radius: 0px;
   }
}

.not-approved {
   color: var(--berntsen-red);
   padding-left: 5px;
}

@media only screen and (max-width: 850px) {
   .tab-header {
      width: 45%;
   }

   .tab-header-active {
      width: 45%;
   }

   .tab-header-filler {
      width: 10%;
   }

   .bi-info-circle {
      display:none;
   }
}

.tab-body-header {
   width: 100%;
   height: 20px;
   background: var(--berntsen-green-35);
   border-right: 2px solid var(--berntsen-green);
   border-left: 2px solid var(--berntsen-green);
}

.tab-body {
   border-right: 2px solid var(--berntsen-green);
   border-left: 2px solid var(--berntsen-green);
   border-bottom: 2px solid var(--berntsen-green);
   padding: 5px;
   overflow-x: auto;
}

.tab-button-group {
   display: flex;
}

.data-key-row {
   background-color: var(--berntsen-gray-15);
}

.react-tooltip {
   font-weight: normal;
   text-align: center;
}

.share-validation {
   width: 50%;
   margin-left: 25%;
}

span.required-text {
   color:crimson;
   font-weight: bold;
}

button.btn-danger {
   background-color: var(--berntsen-red);
}

.alert-warning {
   background-color: var(--berntsen-warning);
   color: white;
   padding: 5px;
}

/* Dark mode!!! */
@media (prefers-color-scheme: dark) {
   .main-content {
      background-color: var(--berntsen-gray-30);
      color: white;
   }

   .page-title {
      background-color: var(--berntsen-gray-30);
   }

   .recharts-wrapper  {
      background-color: white;
   }

   td, th {
      color: white;
   }

   a.link {
      color: white;
   }

   .login a {
      color: var(--berntsen-dark-blue);
   }

   .alert-info a {
      color: var(--berntsen-dark-blue);
   }

   span.required-text {
      color: white;
   }

   .data-parameters .tt {
      color: var(--berntsen-blue);
   }
}

.float-right {
   float: right;
}

.dropdown {
   position: relative;
}
 
.menu {
   position: absolute;
   list-style-type: none;
   padding: 0;
   border: 1px solid grey;
   width: 75px;
}
 
.menu > li {
   margin: 0;
    background-color: white;
}
 
.menu > li:hover {
   background-color: lightgray;
}
 
.menu > li > button {
   width: 100%;
   height: 100%;
   text-align: center;
 
   background: none;
   color: black;
   border: none;
   padding: 5px;
   margin: 0;
   font: inherit;
   cursor: pointer;
}

.menu > li > button:disabled {
   opacity: 50%;
   cursor:default;
}

.menu > li > button > i {
   color: red;
}

.icon-unselected {
   color: orange;
   cursor: context-menu;
}

.search-input {
   width: 120px;
}
